// useForm.js
import { useState } from 'react';
import { _setAxios, _success, _warn } from 'lib/Helper';

const useApproval = (data, fetchTable, hideModal) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if(!data?.status){
            _warn('topRight', 'Error', 'Tolong pilih status terlebih dahulu')
            return
        }
        setLoading(true);
        let endpoint = "perizinan/siswa/status/" + data.id;
        let method = "PUT";

        _setAxios(endpoint, method, {
            status: data?.status
        }).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        loading,
        handleSubmit,
    };
};

export default useApproval;
