import React from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import useApproval from '../hooks/useApproval';

const textApproval = {
    "DIAJUKAN": "Apakah anda yakin ingin mengajukan perizinan ini?",
    "DISETUJUI": "Apakah anda yakin ingin menyetujui perizinan ini?",
    "DITOLAK": "Apakah anda yakin ingin menolak perizinan ini?",
}

const ModalApproval = ({ data, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const {
        loading,
        handleSubmit,
    } = useApproval(data, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            title={"Konfirmasi"}
            open={visible}
            onCancel={hideModal}
            width={300}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
            >
                <p>{textApproval[data.status]}</p>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button onClick={hideModal} disabled={loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Lanjutkan</Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalApproval;
