import React from 'react';

import Config from "../Config";
import axios from 'axios';
import { notification, message, List, Popconfirm } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

import ToastServive from 'react-material-toast';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
const toast = ToastServive.new({
    place: 'topRight',
    duration: 2,
});
// const Cryptr = require('cryptr');
// const cryptr = new Cryptr(Config.secret_key);

export function _config() {
    return Config
}

export const _setAxios = (endpoint, method = "GET", params = {}, type = "", hideMsg = "") => {
    var configAuth = {
        method: method,
        url: Config.api + '/' + endpoint,
        data: params,
        headers: {
            'Accept': 'application/json',
            'Authorization': _getToken()
        },
    }
    if (type === "data") {
        configAuth = {
            method: method,
            url: Config.api + '/' + endpoint,
            data: params,
            type: type,
            headers: {
                'Accept': 'application/json',
                'Authorization': _getToken()
            },
        }
    }

    return axios(configAuth)
        .then(resp => {
            resp.status = true
            return resp
        }).catch(function (error) {
            _validationFormMsg(error, hideMsg)
            error.status = false
            return error
        });
}


export function _toast(type, msg = "") {
    if (type === 'error') {
        toast.error(msg);
    } else if (type === 'success') {
        toast.success(msg);
    }
}

export function catchError(error) {
    if (error.response) {
        if (error.response.data.error) {
            _toast("error", error.response.data.error)
        }
    } else {
        _toast("error", "Server Error")
    }
}

export function _isNumber(x) {
    if (x) {
        return x.toString().replace(/[^0-9]/g, '');
    } else {
        return x
    }
}

export function _fmtRupiah(x) {
    if (x) {
        x = _isNumber(x);
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
        return x
    }
}

export function _isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

export function _isValidMail(email) {

    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
        return true
    }
    else {
        return false
    }

}

export function _isValidPhone(phone) {
    let re = phone.search(/^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/i);
    if (re === -1) {
        return false
    } else {
        return true
    }
}

export function _shortName(str, val = 20, dots = " ...") {
    if (str.length > val) {
        var shortname = str.substring(0, parseInt(val)) + dots;
        return shortname
    }
}
export function _urlProduct(str) {
    return str.replace(Config.base_url, "")
}

export function _error(placement, message, description) {
    notification.error({
        message: message,
        description: description,
        placement,
    });
};
export function _info(placement, message, description) {
    notification.info({
        message: message,
        description: description,
        placement,
    });
};
export function _success(placement, message, description) {
    notification.success({
        message: message,
        description: description,
        placement,
    });
};

export function _warn(placement, message, description) {
    notification.warn({
        message: message,
        description: description,
        placement,
    });
};

export function isAuth(privilege) {
    let pathname = window.location.pathname;
    if (privilege.access[pathname]) {
        if (privilege.access[pathname].status === false) {
            return false
        }
    }
    return true
};

export function _getToken() {
    // return localStorage.getItem('persist:auth') ? cryptr.decrypt(localStorage.getItem('persist:auth')) : "";
    return localStorage.getItem('persist:auth');
};

export function _validationFormMsg(errors, hideMsg) {
    if (!errors) {
        _error('topRight', "Failed", "Server Error.")
    }

    if (errors.response) {
        if (errors.response.data.message) {
            if (errors.response.statusText === "Bad Request") {
                message.warning(errors.response.data.message);
                if (errors.response.data.data.length > 0) {
                    errors.response.data.data.forEach(row => {
                        message.warning(row.message);
                    });
                }

                if (errors.response.status === 401) {
                    setTimeout(function () {
                        localStorage.clear();
                        window.location.href = Config.base_url
                    }, 1000);
                }
                return false
            } else {
                if (errors.response.data.message !== hideMsg) {
                    _error('topRight', errors.response.statusText || "Failed", errors.response.data.message)
                }
                return false
            }
        }
    } else {
        for (var index in errors) {
            if (errors.hasOwnProperty(index)) {
                if (errors[index]) {
                    if (errors[index] !== "Error" && Array.isArray(errors[index])) {
                        errors[index].forEach(msg => {

                            //apakah mengandung kata _id
                            if (msg.indexOf(" id") > 0) {
                                msg = msg.replace(" id", "")
                            }
                            message.warning(msg);
                            return false
                        });
                    }
                }
            }
        }
    }

    return true
};

export function _tableLogActivity(row) {
    return <List.Item className="table-log-activity" style={{ padding: 0 }}>
        <List.Item.Meta
            title={row.created_at}
            description={(row.created_by ? <>{<>{row.created_by} <Popconfirm
                placement="leftTop"
                icon={<HistoryOutlined />}
                showCancel={true}
                title={<>
                    <List.Item className="table-log-activity" style={{ padding: 0 }}>
                        <List.Item.Meta
                            title="Created By"
                        />
                    </List.Item>
                    <List.Item className="table-log-activity" style={{ padding: 0 }}>
                        <List.Item.Meta
                            title={row.created_by}
                            description={row.created_at}
                        />
                    </List.Item>
                    <br />
                    <List.Item className="table-log-activity" style={{ padding: 0 }}>
                        <List.Item.Meta
                            title="Updated By"
                        />
                    </List.Item>
                    <List.Item className="table-log-activity" style={{ padding: 0 }}>
                        <List.Item.Meta
                            title={row.updated_by ? row.updated_by : "-"}
                            description={row.updated_by ? row.updated_at : ""}
                        />
                    </List.Item>
                </>}
            >
                <HistoryOutlined className='table-history' />
            </Popconfirm>
            </>}</> : "-")}
        />
    </List.Item>
};


export function _getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function _beforeUploadImage(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Anda bisa upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image tidak boleh lebih besar dari 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

export function _beforeUploadFile(file) {
    console.log("file.type", file.type)
    let fileAllow = ["image/jpeg", "image/png", "image/webp", "application/pdf"]
    if (!fileAllow.includes(file.type)) {
        message.error('Anda bisa upload JPG/PNG/WEBP/PDF file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('File tidak boleh lebih besar dari 2MB!');
    }
    return fileAllow.includes(file.type) && isLt2M;
}

export function _getBase64Upload(file) {
    let base64str = file;
    let base64Ar = base64str.split(",");

    if (base64Ar.length > 0) {
        base64str = base64Ar[1]
    }

    return base64str;
}

export const _getBase64ImageUrl = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


/* TABLE */

export const getParamTableSearch = (tableParams, value) => {
    var params = {
        ...tableParams,
        search: value,
        pagination: { ...tableParams.pagination },
        sorting: tableParams.sorting,
        filtering: { ...tableParams.filtering }
    }
    return params
};

export const getParamTableChange = (tableParams, pagination) => {
    var params = {
        ...tableParams,
        search: tableParams.search,
        pagination: { ...pagination },
        sorting: tableParams.sorting,
        filtering: { ...tableParams.filtering }
    }
    return params
};

export const getParamTableSort = (tableParams, value) => {
    var params = {
        ...tableParams,
        search: tableParams.search,
        pagination: { ...tableParams.pagination },
        sorting: [{
            field: (tableParams.sorting.length > 0 ? tableParams.sorting[0].field : "id"),
            sort: value,
            urutan: (tableParams.sorting.length > 0 ? tableParams.sorting[0].urutan : 1)
        }],
        filtering: { ...tableParams.filtering }
    }
    return params
};
export const getParamTableSortField = (tableParams, value) => {
    var params = {
        ...tableParams,
        search: tableParams.search,
        pagination: { ...tableParams.pagination },
        sorting: [{
            sort: (tableParams.sorting.length > 0 ? tableParams.sorting[0].sort : "ASC"),
            field: value,
            urutan: (tableParams.sorting.length > 0 ? tableParams.sorting[0].urutan : 1)
        }],
        filtering: { ...tableParams.filtering }
    }
    return params
};

export const getParamTableFiltering = (tableParams, name, value) => {
    var params = {
        ...tableParams,
        search: tableParams.search,
        pagination: { ...tableParams.pagination },
        sorting: tableParams.sorting,
        filtering: { ...tableParams.filtering, [name]: value }
    }

    return params
};

export const getParamTempTableFiltering = (tableParams, tempFilter) => {
    var params = {
        ...tableParams,
        search: tableParams.search,
        pagination: { ...tableParams.pagination },
        sorting: tableParams.sorting,
        filtering: { ...tableParams.filtering, ...tempFilter }
    }

    return params
};


export const getParamTableFetch = (tableParams) => {
    var params = {
        ...tableParams,
        search: tableParams.search,
        pagination: { ...tableParams.pagination },
        sorting: tableParams.sorting,
        filtering: { ...tableParams.filtering }
    }

    return params
};
export const getParamTable = (type, tableParams, value = null, name = null, pagination = {}) => {
    let params = {}
    type = type.toLowerCase()
    switch (type) {
        case "search":
            params = getParamTableSearch(tableParams, value)
            break;
        case "change":
            params = getParamTableChange(tableParams, pagination)
            break;
        case "sort":
            params = getParamTableSort(tableParams, value)
            break;
        case "sort_field":
            params = getParamTableSortField(tableParams, value)
            break;
        case "filter":
            params = getParamTableFiltering(tableParams, name, value)
            break;
        default:
            params = getParamTableFetch(tableParams)
            break;
    }

    return params
};

export const _getDate = (value) => {
    let res = value
    if (value && value instanceof Date) {
        res = moment(value).format("DD-MM-YYYY")
    }

    return res ? res : null
};

export const _getYear = (value) => {
    let res = value
    if (value && value instanceof Date) {
        res = moment(value).format("YYYY")
    }

    return res ? res : null
};

export const _getFile = async (value) => {
    let file = null
    if (value && value.length > 0) {
        if (value[0].url && (value[0].url.includes("https://") || value[0].url.includes("http://"))) {
        } else {
            file = await _getBase64(value[0].originFileObj)
            file = _getBase64Upload(file)
        }
    }

    return file
};

export const _scrollFloatBtn = async (value) => {
    // Condition float btn with scroll
    if (isMobile) {

        const el = document.getElementById("float-btn-center")
        if (el) {
            var prevScrollpos = window.pageYOffset;
            window.onscroll = function () {
                var currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos || prevScrollpos < currentScrollPos) {
                    el.style.bottom = "-50px";
                }
            }

            // Condition float btn with scroll stop
            var timer = null;
            window.addEventListener('scroll', function () {
                if (timer !== null) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function () {
                    el.style.bottom = "60px";
                }, 150);
            }, false);
        }

    }
};


export const _getDistance = (origin, destination) => {
    // return distance in meters
    var lon1 = toRadian(origin[1]),
        lat1 = toRadian(origin[0]),
        lon2 = toRadian(destination[1]),
        lat2 = toRadian(destination[0]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
}
function toRadian(degree) {
    return (degree * Math.PI / 180);
}

export const _logout = () => {
    _setAxios("logout", "POST", {
    }).then(resp => {
        if (resp.status === true) {
            localStorage.clear();
            window.location.href = Config.base_url + "/login"
        }
    })
}