import React from 'react';
import { Tag } from 'antd';

const status = {
  "DRAFT": "",
  "DIAJUKAN": "grey",
  "DISETUJUI": "blue",
  "DITOLAK": "red",
  "AUTO APPROVAL": "blue",
}

const LabelStatusPerizinan = ({ ...props }) => {

  if (props.status) {
    return (
      <Tag color={status[props.status]}> {props.status}</Tag>
    );
  }
  return (
    "-"
  );
};
export default LabelStatusPerizinan;
