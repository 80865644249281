import React from "react";
import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";
import { LabelStatusPerizinan } from "components/labels";

const columns = (action, modalForm, modalDelete, modalApproval, modalDetail) => [
  {
    title: 'No',
    key: 'rownum',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nama',
    dataIndex: 'nama',
    sorter: false,
  }, {
    title: 'Tahun Ajaran',
    dataIndex: 'tahun_ajaran',
    sorter: false,
  }, {
    title: 'Semester',
    dataIndex: 'tipe_semester',
    sorter: false,
  }, {
    title: 'Kelas/Rombel',
    sorter: false,
    render: (a, row) => row.rombel_nama ? row.rombel_nama : "-",
  }, {
    title: 'Jenis Perizinan',
    dataIndex: 'perizinan_jenis_nama',
    sorter: false,
  }, {
    title: 'Tanggal',
    sorter: false,
    render: (a, row) => <>{row.tgl_awal ? row.tgl_awal : "0"} - {row.tgl_akhir ? row.tgl_akhir : "0"}</>,
  }, {
    title: 'Status',
    sorter: false,
    render: (a, row) => <LabelStatusPerizinan status={row.status} />
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete} modalApproval={modalApproval} modalDetail={modalDetail}/>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns