import React from 'react';
import { Badge, Descriptions, Modal, Typography } from 'antd';
const { Text, Link } = Typography;

const ModalDetail = ({ data, visible, hideModal }) => {
    return (
        <Modal
            title="Detail"
            open={visible}
            onCancel={hideModal}
            footer={null}
        >

            <Descriptions
                bordered
                column={1}
                labelStyle={{ fontWeight: 'bold', width: '150px', backgroundColor: '#f7f7f7' }}
                contentStyle={{ backgroundColor: '#ffffff' }}
            >
                <Descriptions.Item label={<>Nama Siswa</>}>
                    <Text strong>{data.nama}</Text>
                </Descriptions.Item>

                <Descriptions.Item label="Tahun Ajaran">
                    {data.tahun_ajaran}
                </Descriptions.Item>

                <Descriptions.Item label="Semester">
                    {data.tipe_semester}
                </Descriptions.Item>

                <Descriptions.Item label="Rombel">
                    {data.rombel_nama}
                </Descriptions.Item>

                <Descriptions.Item label={<>Tanggal</>}>
                    {data.tgl_awal} - {data.tgl_akhir}
                </Descriptions.Item>

                <Descriptions.Item label={<>Status</>}>
                    <Badge
                        status={data.status === "AUTO APPROVAL" || data.status === "DISETUJUI" ? "success" : "processing"}
                        text={data.status}
                    />
                </Descriptions.Item>

                <Descriptions.Item label="Jenis Izin">
                    {data.perizinan_jenis_nama}
                </Descriptions.Item>

                <Descriptions.Item label="Keterangan">
                    {data.keterangan || "Tidak ada keterangan"}
                </Descriptions.Item>

                {data.file && (
                    <Descriptions.Item label={<>File</>}>
                        <Link href={`${data.main_path}${data.file}`} target="_blank" rel="noopener noreferrer">
                            Download Dokumen
                        </Link>
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Dibuat oleh">
                    {data.created_by}
                </Descriptions.Item>

                <Descriptions.Item label="Tanggal Dibuat">
                    {data.created_at}
                </Descriptions.Item>

                {data.approved_at && (
                    <>
                        <Descriptions.Item label="Disetujui Pada">
                            {data.approved_at}
                        </Descriptions.Item>
                        <Descriptions.Item label="Disetujui Oleh">
                            {data.approved_by}
                        </Descriptions.Item>
                    </>
                )}
            </Descriptions>

        </Modal>
    );
};

export default ModalDetail;
