import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Divider, Radio, Space } from 'antd';

import { getParamTable } from '../../lib/Helper';

const sorts = [
  {
    value: "ASC",
    label: "Ascending",
  },
  {
    value: "DESC",
    label: "Descending",
  }
];

const SortingTable = ({ table, sortFields, fetch }) => {
  const [sortField, setSortField] = useState(
    table.sorting.length > 0 ? table.sorting[0].field : ""
  );
  const [sortValue, setSortValue] = useState(
    table.sorting.length > 0 ? table.sorting[0].sort : ""
  );

  const handleSortFieldChange = (e) => {
    const params = getParamTable("sort_field", table, e.target.value);
    fetch(params, isMobile);
    setSortField(e.target.value);
  };

  const handleSortValueChange = (e) => {
    const params = getParamTable("sort", table, e.target.value);
    fetch(params, isMobile);
    setSortValue(e.target.value);
  };

  return (
    <>
      <Radio.Group
        onChange={handleSortFieldChange}
        value={sortField}
        name="sort_field"
      >
        <Space direction="vertical">
          {sortFields.map((row, i) => (
            <Radio key={i} value={row.value}>
              {row.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Divider orientation="left" />
      <Radio.Group
        onChange={handleSortValueChange}
        value={sortValue}
        name="sort_value"
      >
        <Space direction="vertical">
          {sorts.map((row, i) => (
            <Radio key={i} value={row.value}>
              {row.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </>
  );
};

export default SortingTable;
