import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, getParamTable, _validationFormMsg, getParamTempTableFiltering } from '../../../lib/Helper';
import { schemaPenghargaan } from './schema';
import validate from 'validate.js';

import { Table, Row, Col, Divider, Button, PageHeader, Input, Tooltip, Modal, Form, Select } from 'antd';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { bintangPenghargaanOptions } from '../../../data/options';

const { Option } = Select;

class Penghargaan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [{
                    field: "id",
                    sort: "DESC",
                    urutan: 1
                }],
                filtering: {
                    tahun_ajaran: null
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
            ddl:{
                tahunAjaran: []
            }
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getTahunAjaranDDL()
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran/tahun", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tatibsi/penghargaan/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaPenghargaan);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaPenghargaan);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },

            values: {},
            errors: {},
            fileList: null
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    kode: row.kode ? row.kode : null,
                    bintang: row.bintang ? row.bintang : null,
                    kriteria: row.kriteria ? row.kriteria : null,
                    penghargaan: row.penghargaan ? row.penghargaan : null,
                    tahun_ajaran_id: row.tahun_ajaran_id ? row.tahun_ajaran_id : null,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaPenghargaan)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        let param = {
            kode: values.kode ? values.kode : null,
            bintang: values.bintang ? values.bintang : null,
            kriteria: values.kriteria ? values.kriteria : null,
            penghargaan: values.penghargaan ? values.penghargaan : null,
            tahun_ajaran_id: this.props.privilege.tahun_ajaran ? this.props.privilege?.tahun_ajaran?.id : null,
        }

        // Define Network
        let method = "POST"
        let endpoint = "tatibsi/penghargaan"
        if (values.id) {
            param.tahun_ajaran_id = values.tahun_ajaran_id ? values.tahun_ajaran_id : null
            method = "PUT"
            endpoint = "tatibsi/penghargaan/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Delete Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tatibsi/penghargaan/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------


    render() {
        const { table, values, errors, ddl } = this.state;

        const modalFilter = <Modal
            title="Filter Penghargaan"
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <Select
                        showSearch
                        allowClear
                        name="tahun_ajaran"
                        placeholder="Tahun Ajaran"
                        onChange={(e, newValue) => {
                            this.setTempTableFilter("tahun_ajaran", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.thn_awal + "-" + row.thn_akhir}>{row.thn_awal} / {row.thn_akhir}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>

        const modalForm = <Modal
            title="Form Data Penghargaan"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Kode *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="kode"
                        type="text"
                        onChange={this.handleChange}
                        value={values.kode || ''}
                        error={this.hasError('kode')}
                        helperText={
                            this.hasError('kode') ? errors.kode[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={bintangPenghargaanOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Bintang *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="bintang"
                            type="text"
                            error={this.hasError('bintang')}
                            helperText={
                                this.hasError('bintang') ? errors.bintang[0] : null
                            }
                        />}
                        value={values.bintang || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("bintang", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Kriteria *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="kriteria"
                        type="text"
                        onChange={this.handleChange}
                        value={values.kriteria || ''}
                        error={this.hasError('kriteria')}
                        helperText={
                            this.hasError('kriteria') ? errors.kriteria[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Penghargaan"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="penghargaan"
                        type="text"
                        onChange={this.handleChange}
                        value={values.penghargaan || ''}
                        error={this.hasError('penghargaan')}
                        helperText={
                            this.hasError('penghargaan') ? errors.penghargaan[0] : null
                        }
                    />
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Data Penghargaan"
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Kode',
                                    dataIndex: 'kode',
                                },
                                {
                                    title: 'Bintang',
                                    dataIndex: 'bintang',
                                },
                                {
                                    title: 'Kriteria',
                                    dataIndex: 'kriteria',
                                },
                                {
                                    title: 'Penghargaan',
                                    dataIndex: 'penghargaan',
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
                {modalFilter}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Penghargaan);
