import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { getParamTable } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Nama',
                  dataIndex: 'siswa_nama',
                  sorter: false,
                }, {
                  title: 'Kelas/Rombel',
                  dataIndex: 'rombel_nama',
                  sorter: false,
                  render: (a, row) => row.rombel_nama ? row.rombel_nama : "-",
                }, {
                  title: 'Tanggal',
                  dataIndex: 'tgl',
                  sorter: false,
                }, {
                  title: 'Jam',
                  sorter: false,
                  render: (a, row) => <>
                    <span style={{
                      fontSize: "70%",
                      fontStyle: "italic"
                    }}>{row.waktu_masuk}</span> <br />
                    to <br />
                    <span style={{
                      fontSize: "70%",
                      fontStyle: "italic"
                    }}>{row.waktu_pulang}</span> <br />
                  </>,
                }, {
                  title: 'Jarak',
                  sorter: false,
                  render: (a, row) => <>
                    Masuk: <b>{row.radius_masuk ? parseFloat(row.radius_masuk).toFixed(2) + "m" : "-"}</b> <br />
                    Pulang: <b>{row.radius_pulang ? parseFloat(row.radius_pulang).toFixed(2) + "m" : "-"}</b></>,
                }, {
                  title: 'Durasi',
                  sorter: false,
                  render: (a, row) => <>
                    Telat: <b>{row.durasi_telat ? row.durasi_telat : "-"}</b> <br />
                    Pulang Cepat: <b>{row.durasi_pulang_cepat ? row.durasi_pulang_cepat : "-"}</b></>,
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.detail ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail ? (<Menu.Item key={0} onClick={this.props.modalDetail.bind(this, true, row)}><InfoCircleOutlined /> Detail</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
