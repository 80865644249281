import React from 'react';
import { DatePicker } from 'antd';
import { dateFormat } from '../../constant/constant_format';

const { RangePicker } = DatePicker;


const RangeDate = ({ ...props }) => {
    return (
        <RangePicker 
        {...props}
        style={props.style ? props.style : { width: '100%' }}
        format={dateFormat} 
        picker="date" 
        />
    );
};
export default RangeDate;
