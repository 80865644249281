import React from 'react';
import { Row, Col, Image, Button, Skeleton, Typography } from 'antd';
import { connect } from 'react-redux'
import { ClockCircleFilled, ScheduleOutlined } from '@ant-design/icons';
import { noImage } from '../../data/image';
import AbsensiCheckLog from './AbsensiCheckLog';
import { _setAxios } from '../../lib/Helper';
import moment from 'moment';

const { Text } = Typography;
const dateNow = moment(new Date()).format("YYYY-MM-DD")

class Absensi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      absensi: {
        data: {},
        visible: false
      },
      absensiCheckLog: {
        data: {},
        visible: false,
        loading: true
      },
    };
  }

  modalAbsensi = (visible = false, data = {}) => {
    this.setState({
      absensi: {
        data: data,
        visible: visible
      },
    })
  }

  getAbsensiById = async (id) => {
    if (!id) {
      return [];
    }
    this.setState({
      absensiCheckLog: {
        ...this.state.absensiCheckLog,
        loading: true
      }
    })
    return await _setAxios("presensi/siswa/temporary/" + id + "/" + dateNow, "GET", null, null, "Data tidak ditemukan").then((resp) => {
      if (resp.status && resp.data) {
        this.setState({
          absensiCheckLog: {
            data: resp.data.data,
            visible: true,
            loading: false
          }
        })
      } else {
        this.setState({
          absensiCheckLog: {
            ...this.state.absensiCheckLog,
            loading: false
          }
        })
      }

      return resp;
    }).catch((err) => {
      this.setState({
        absensiCheckLog: {
          ...this.state.absensiCheckLog,
          loading: false
        }
      })
      return err;
    });
  };

  componentDidMount() {
    this.getAbsensiById(this.props.privilege?.profile_ex?.siswa_id)
  }


  render() {
    const { absensiCheckLog } = this.state
    const { loading, privilege } = this.props
    const { presensi } = privilege
    const presensiSetting = presensi?.setting
    const absensiCheckLogIn = absensiCheckLog.data?.in
    const absensiCheckLogOut = absensiCheckLog.data?.out

    if (loading) {
      return <Skeleton.Input active={true} className="skeleton-statistik" />
    } else {
      return (
        <div className="dashboard-column" style={{
          minHeight: 210,
          borderColor: "#F7BE2A",
          // opacity: .3
        }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}><h5>Absensi Hari Ini</h5></Col>
            <Col xs={24} sm={8}>
              <div className="dashboard-column" style={{
                borderColor: "#52C41A",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 5
              }}>
                <Row>
                  <Col xs={10}><Image src={absensiCheckLogIn && absensiCheckLogIn.foto ? absensiCheckLog.data.main_path + absensiCheckLogIn.foto : noImage} className="image-circle" /></Col>
                  <Col xs={14}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold"
                      }}
                    >Masuk</div>
                    <div style={{
                      color: "#52C41A",
                      fontSize: 20
                    }}>{absensiCheckLogIn && absensiCheckLogIn.waktu ? absensiCheckLogIn.waktu.substring(0, 5) : "-"}</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="dashboard-column" style={{
                borderColor: "#52C41A",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 5
              }}>
                <Row gutter={[16, 16]}>
                  <Col xs={10}><Image src={absensiCheckLogOut && absensiCheckLogOut.foto ? absensiCheckLog.data.main_path + absensiCheckLogOut.foto : noImage} className="image-circle" /></Col>
                  <Col xs={14}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold"
                      }}
                    >Pulang</div>
                    <div style={{
                      color: "#52C41A",
                      fontSize: 20
                    }}>{absensiCheckLogOut && absensiCheckLogOut.waktu ? absensiCheckLogOut.waktu.substring(0, 5) : "-"}</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div style={{
                width: "100%"
              }}>
                <div
                  style={{
                    padding: "16px 0px"
                  }}
                >
                  <Text strong>Check Log</Text><br />
                  <ClockCircleFilled style={{ color: "#F7BE2A" }} /> {presensiSetting.jam_masuk} - {presensiSetting.jam_pulang} </div>
                <div><Button
                  block
                  style={{
                    background: "#10B582",
                    color: "#fff",
                    border: "1px solid #10B582",
                    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
                    borderRadius: 5,
                    width: "100%"
                  }}
                  onClick={
                    this.modalAbsensi.bind(this, true)
                  }
                ><ScheduleOutlined /> Rekam Kehadiran</Button></div>
              </div>
            </Col>
          </Row>

          {
            this.state.absensi.visible ?
              <AbsensiCheckLog
                data={this.state.absensi.data}
                visible={this.state.absensi.visible}
                hideModal={this.modalAbsensi.bind(this, false)}
              /> : ""
          }
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Absensi)