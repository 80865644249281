import React from 'react';
import { Table, Row, Col, Pagination } from 'antd';
import { getParamTable } from '../../lib/Helper';
import { BrowserView } from 'react-device-detect';

class Desktop extends React.Component {
  componentDidMount() {
    this.props.fetchTable();
  }

  render() {
    const {columns, state} = this.props;
    const { table } = state;

    return (
      <BrowserView>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total };
                const params = getParamTable("change", table, null, null, pagination);
                this.props.fetch(params);
              }}
            />
          </Col>
        </Row>
      </BrowserView>
    );
  }
}

export default Desktop;
