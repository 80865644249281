import React from 'react';
import { Select } from 'antd';

const SelectStatusPerizinan = ({ ...props }) => {
  return (
    <Select
      placeholder="Pilih status perizinan"
      style={{
        width: '100%',
      }}
      options={[
        {
          value: 'DRAFT',
          label: 'Draft',
        }, {
          value: 'DIAJUKAN',
          label: 'Diajukan',
        }, {
          value: 'DISETUJUI',
          label: 'Disetujui',
        }, {
          value: 'DITOLAK',
          label: 'Ditolak',
        }, {
          value: 'AUTO APPROVAL',
          label: 'Auto Approval',
        }
      ]}
      {...props}
    />
  );
};
export default SelectStatusPerizinan;
