import React from 'react';
import { Alert, Breadcrumb } from 'antd';
import { isAuth, _setAxios } from '../../lib/Helper';
import { Row, Col } from 'antd';
import { connect } from 'react-redux'
import AuthRedirect from '../../components/redirect/AuthRedirect'
import { DoubleRightOutlined, HomeOutlined } from '@ant-design/icons';
import Welcome from './Welcome'
import Absensi from './Absensi'

import "./Home.css"
import Point from './Point';
import JadwalPelajaran from './JadwalPelajaran';
class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      auth: true,
      loading: true,

      latitude: '',
      longitude: '',
    };

    this.getMyLocation = this.getMyLocation.bind(this)

  }

  getDashboard = () => {
    _setAxios("dashboard/utama", "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          loading: false
        });
      }
    })
  }

  onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })

      }, (error) => {
        this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }
  }

  componentDidMount() {
    this.getDashboard()
    this.getMyLocation()

  }

  render() {

    const { presensi, profile_ex } = this.props.privilege

    const { loading } = this.state

    console.log("absensi", presensi);

    if (isAuth(this.props.privilege)) {
      return (
        <>
          <Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
          <div className="" style={{ minHeight: 360 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                {
                  profile_ex && profile_ex.siswa_alasan_akun_ditangguhkan ?
                    <Alert
                      message="Warning"
                      description={profile_ex.siswa_alasan_akun_ditangguhkan}
                      type="warning"
                      showIcon
                      closable
                    /> : ""
                }
              </Col>
              <Col xs={24}>
                <Welcome data={this.props.privilege} loading={loading} />
              </Col>
              {presensi?.is_allow ? <Col xs={24} sm={12}>
                <Absensi loading={loading} data={this.props.privilege} />
              </Col> : ""}
              <Col xs={24} sm={presensi?.is_allow ? 12 : 24}>
                <Point total={this.state.total} loading={loading} />
              </Col>
              <Col xs={24}>
                <JadwalPelajaran />
              </Col>
            </Row >
          </div >
        </>
      )
    } else {
      return <AuthRedirect />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Home)