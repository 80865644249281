import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectJenisPerizinan } from 'components/select';
import { RangeDate } from 'components/datetime';
import { UploadFile } from 'components/upload-files';

const { TextArea } = Input;
const ModalForm = ({ data, privilege, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        handleSubmit,
    } = useForm(data, privilege, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Perizinan Siswa"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Jenis Perizinan"
                            name="perizinan_jenis_id"
                            rules={[{ required: true, message: 'Jenis Perizinan harus diisi!' }]}>
                            <SelectJenisPerizinan
                                allowClear
                                tipePerizinan="siswa"
                            />
                        </Form.Item>
                        <Form.Item label="Tanggal" name="tgl" rules={[{ required: true, message: 'Tanggal harus diisi!' }]}>
                            <RangeDate />
                        </Form.Item>
                        <Form.Item
                            label="Keterangan"
                            name="keterangan"
                            rules={[{ required: true, message: 'Keterangan harus diisi!' }]}>
                            <TextArea />
                        </Form.Item>
                        <Form.Item label="File" name="file" rules={[{ required: data.id ? false : true, message: 'File harus diisi!' }]}>
                            <UploadFile
                                isValidation={true}
                                type="jpg, png & pdf"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;