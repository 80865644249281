import React from 'react';
import { Button, Dropdown } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownCircleOutlined, EditOutlined, InfoCircleOutlined, SendOutlined } from '@ant-design/icons';

class Action extends React.Component {
    render() {
        const { row, action, modalForm, modalDelete, modalApproval, modalDetail } = this.props;
        const items = [];

        if (!action?.update && !action?.delete && !action?.detail && !action?.approval && !action?.request_approval) {
            return "-"
        }

        if (action?.update && row.status === "DRAFT") {
            items.push({
                key: '1',
                label: "Update",
                onClick: () => {
                    modalForm(true, row)
                },
                icon: <EditOutlined />,
            })
        }

        if (action?.detail) {
            items.push({
                key: '3',
                label: "Detail",
                icon: <InfoCircleOutlined />,
                onClick: () => {
                    modalDetail(true, row)
                },
            })
        }

        if (action?.request_approval && row.status === "DRAFT") {
            items.push({
                key: '4',
                label: "Ajukan Perizinan",
                icon: <SendOutlined rotate={-40} />,
                onClick: () => {
                    modalApproval(true, {
                        id: row.id,
                        status: "DIAJUKAN"
                    })
                },
            })
        }

        if (action?.approval && row.status === "DIAJUKAN") {
            items.push({
                key: '5',
                label: "Setujui Perizinan",
                icon: <CheckCircleOutlined />,
                onClick: () => {
                    modalApproval(true, {
                        id: row.id,
                        status: "DISETUJUI"
                    })
                },
            })
            items.push({
                key: '6',
                label: "Tolak Perizinan",
                icon: <CloseCircleOutlined />,
                onClick: () => {
                    modalApproval(true, {
                        id: row.id,
                        status: "DITOLAK"
                    })
                },
            })
        }

        if (action?.delete && row.status === "DRAFT") {
            items.push({
                key: '2',
                label: "Delete",
                icon: <CloseCircleOutlined />,
                onClick: () => {
                    modalDelete(true, row)
                },
            })
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown >
        );
    }
}


export default Action;
