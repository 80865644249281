import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, PageHeader, Breadcrumb } from 'antd';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import ModalForm from '../modal/Form';
import Filter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from 'components/search-table/SearchTable';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import ModalApproval from '../modal/Approval';
import ModalDetail from '../modal/Detail';
import { isAuth } from 'lib/Helper';
import { AuthRedirect } from 'components/redirect';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const Perizinan = ({ privilege }) => {
  const {
    table,
    formState,
    filterState,
    deleteState,
    approvalState,
    detailState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalForm,
    modalDelete,
    modalApproval,
    modalDetail,
  } = useList(privilege?.tahun_ajaran, privilege?.semester);

  const access = privilege.access["/presensi/perizinan?tab=siswa"]
  if (!isAuth(privilege) || access === undefined) {
    return <AuthRedirect />
  }

  const action = access.action

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Presensi</Breadcrumb.Item>
          <Breadcrumb.Item>Perizinan</Breadcrumb.Item>
        </Breadcrumb>}
      />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title="Perizinan"
          subTitle="Data perizinan siswa"
          extra={[
            action?.create && (
              <Button
                type='primary'
                onClick={() => modalForm(true)}
                icon={<PlusOutlined />}>
                Tambah
              </Button>
            ),
          ]} />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableWithFilter
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filterState }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalForm, modalDelete, modalApproval, modalDetail)}
            />
          </Col>
        </Row>
      </div>
      {formState.visible && (
        <ModalForm
          data={formState.data}
          privilege={privilege}
          visible={formState.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {filterState.visible && (
        <Filter
          visible={filterState.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filterState.values}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      {approvalState.visible && (
        <ModalApproval
          data={approvalState.data}
          visible={approvalState.visible}
          hideModal={() => modalApproval(false)}
          fetchTable={fetchTable}
        />
      )}
      {detailState.visible && (
        <ModalDetail
          data={detailState.data}
          visible={detailState.visible}
          hideModal={() => modalDetail(false)}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(Perizinan);