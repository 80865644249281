import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

const fetch = (keyword, callback, tahunAjaranId, semesterId) => {
  if (!tahunAjaranId || !semesterId) {
    return callback([])
  }
  _setAxios("rombel/dropdown/"+tahunAjaranId+"/"+semesterId, "GET")
    .then(body => {
      let results = []
      body.data.data.forEach(row => {

        results.push({
          value: row.id,
          label: row.nama,
        })
      });

      callback(results);
    })
};
const SearchInput = (props) => {
  return (
    <Select
      {...props}
      showSearch
      allowClear
      placeholder={
        props.placeholder ? props.placeholder : 'Pilih rombel'
      }
      style={props.style ? props.style : { width: '100%' }}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
    />
  );
};
const SelectRombel = ({ ...props }) => {
  const [data, setData] = useState([]);
  const handleSearch = (newValue) => {
    fetch(newValue, setData, props.tahunAjaranId, props.semesterId);
  };

  useEffect(() => {
    fetch(null, setData, props.tahunAjaranId, props.semesterId);
  }, [props.tahunAjaranId, props.semesterId]);

  return <SearchInput
    {...props}
    onSearch={handleSearch}
    options={data}
  />
}

export default SelectRombel;